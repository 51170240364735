import { navigate } from 'gatsby';
import { useEffect } from 'react';

const Services = () => {
  useEffect(() => {
    navigate('/404');
  }, []);

  return null;
};

export default Services;
